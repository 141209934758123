// Navigation
.header__navigation-bar {
  margin-top: -1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include bp(medium-up) {
    padding: 0 1rem;
  }
}

.payments {
  display: none;

  @include bp(medium-up) {
    display: block;
  }
}

.navigation {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1;

  li {
    font-size: 1.2rem;
    display: inline-block;
    position: relative;
    transition: max-height 150ms ease-in-out;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    a {
      color: inherit;
      text-decoration: none;
      padding: 1rem 1rem;
      display: inline-block;
      width: 100%;
    }

    .sub-menu {
      opacity: 0;
      left: 0;
      z-index: 9999;
      overflow: hidden;
      max-height: 0;
      pointer-events: none;
      width: 100%;
      min-width: 250px;
      transition: opacity 150ms ease-in-out, max-height 150ms ease-in-out;
      padding: 0;
      color: #333;

      @include bp(medium-up) {
        position: absolute;
      }

      li {
        display: block;
        padding-left: 1rem;
      }

      li:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }

    &:hover {
      .sub-menu {
        max-height: 50rem;

        // Adjust this to height of menu
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  @include bp(medium-up) {
    flex-direction: row;

    > li {
      border-top: 0;
    } 

    li {
      font-size: 1.1rem;
    }
  }
}

.footer__navigation {
  background: $orange;
}

.header__navigation {
  color: $white;

  li {
    background: $orange;

    &:hover {
      background: darken($orange, 5%);
    }

    &.active {
      background: darken($lightestGrey, 5%);
    }
  }

  > li {
    &:hover {
      background: darken($lightestGrey, 5%);
    }

    &.active {
      background: $lightestGrey;
    }

    @include bp(medium-up) {
      &:first-child {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }

      &:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }
}
