html {
  background: $lightGrey;
  color: $mediumGrey;
  font-size: 14px;

  @include bp(medium-up) {
    font-size: 12px;
  }
}

.quean-clean-banner {
  text-align: center;
  background: lighten($orange, 10%);
  padding: 0.6rem;
  font-size: 1.2rem;
  color: #333;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.quean-clean-banner .btn {
  background: darken($orange, 20%);
  border-radius: 3px;
  padding: 0.8rem;
  text-decoration: none;
  color: #fff;
}

.page-wrap {
  background: $white;
  max-width: 955px;
  margin: 0 auto;
  position: relative;
}

// header
.header {
  background: $darkGrey;
  color: $white;
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  @include bp(medium-up) {
    justify-content: space-between;
  }

  .logo {
    color: $orange;
    background: url(../images/logo.png) no-repeat center;
    min-width: 286px;
    padding-top: 48px;
    font-size: 1.8rem;
    text-align: center;
  }

  .logo,
  .helpline {
    margin: 1rem 0;
  }

  .helpline {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;

    p {
      margin: 0;
      padding: 0;
    }

    a,
    .phone {
      text-decoration: none;
      color: $orange;
    }
  }
}

.payments {
  text-indent: -9999999px;
  background: url(../images/payment_types.png) no-repeat;
  width: 238px;
  height: 36px;
}

.main-content {
  font-size: 1.2rem;
  padding: 0 1.5rem;

  h1 {
    margin-bottom: 0;
  }

  .content {
    display: flex;

    @include bp(small) {
      flex-direction: column;
    }
  }

  .sidebar {
    img {
      max-width: 80%;
      margin: 0 auto;
      display: block;
    }

    @include bp(medium-up) {
      padding-left: 1rem;

      img {
        max-width: 100%;
      }
    }
  }

  li {
    padding: 0.5rem;
  }
}

.product-page {
  @include bp(small) {
    .details {
      order: 2;
    }
  }
}

.product__price {
  font-weight: bold;
  font-size: 1.6rem;
}

// Footer
.footer {
  margin-top: 2rem;
  background: url(../images/footer_graphic.png) no-repeat bottom left;
  color: $white;
  position: relative;

  .products {
    display: flex;
    align-items: center;
    padding-bottom: 4rem;
    margin: 0 1rem;
  }

  .product-line {
    max-width: 100%;
  }

  .guarantee {
    margin: 0 auto;
    font-size: 2rem;
    text-align: center;
    color: $darkGrey;

    strong {
      font-size: 4rem;
    }
  }

  @include bp(small) {
    .products {
      flex-wrap: wrap;
    }

    .product-line {
      order: 2;
    }
  }
}

.copyright {
  background: $darkGrey;
  display: block;
  padding: 1rem;
  color: $white;
  text-align: center;
}

.checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
}
