.button--queanclean {
  font-size: 1.6rem;
  background: url('../images/quean-clean-logo-no-text.png') no-repeat 1rem;
  background-size: auto 60%;
  background-color: #38383a;
  color: $white;
  padding: 1.3rem;
  padding-left: 5rem;
  text-align: left;
  line-height: 2rem;
}

.redirect {
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
  font-size: 1.2rem;

  &--show {
    opacity: 1;
    pointer-events: all;
  }
}

.redirect__content {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 4rem 1rem 4rem;
  box-shadow: 0 0 20rem 0 rgba(0, 0, 0, 0.75);
}

.loader {
  margin: 1rem;
  text-align: center;
}

.loader__blob {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loader__blob1 {
  animation-delay: -0.32s;
}

.loader__blob2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
