
// Grid
.grid-2 {
  width: 50%;
}

.grid-3 {
  width: 33.3%;
}

.grid-4 {
  width: 25%;
}

.grid-5 {
  width: 20%;
}

.grid-6 {
  width: 16.6%;
}

.grid-8 {
  width: 12.5%;
}

.grid-10-6 {
  width: 65%;
}

.grid-10-4 {
  width: 35%;
}

.grid-20-13 {
  width: 65%;

  @include bp(small) {
    width: 100%;
  }
}

.grid-20-7 {
  width: 35%;

  @include bp(small) {
    width: 100%;
  }
}
