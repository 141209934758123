@mixin bp($point) {
  @if $mediaQueries == true {
    @if $point == large {
      @media (min-width: 1250px) { @content; }
    }
    @else if $point == medium {
      @media (min-width: 800px) and (max-width: 1250px) { @content; }
    }
    @else if $point == small {
      @media (max-width: 799px)  { @content; }
    }
    @else if $point == medium-down {
      @media (max-width: 1250px)  { @content; }
    }
    @else if $point == medium-up {
      @media (min-width: 800px)  { @content; }
    }
  }
  
  @else {
    @content;
  }
}
$pixelBase : 12;
