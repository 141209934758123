$mediaQueries: true;

$white: #fff;
$lightGrey: #E5E5E5;
$lightestGrey: #a4a5a1;
$mediumGrey: #4C4C4C;
$darkGrey: #666666;
$orange: #FF9123;

@import 'config';
@import 'basic';
@import 'layout';
@import 'modules';
@import 'theme';
@import 'navigation';
@import 'quean-clean-redirect';
