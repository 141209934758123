strong,
b {
  color: $orange;
}

// Quotes
.quotes {
  background: #e5e5e5;
  display: flex;

  q {
    color: #ff8200;
  }

  .quote {
    padding: 1rem;
  }

  @include bp(small) {
    flex-direction: column;
  }
}

.author {
  color: #4c4c4c;
  font-weight: bold;
  text-align: right;
  margin-top: 1rem;
}

// Forms
select {
  display: block;
  margin: 1rem auto;
}

label {
  text-align: center;
  display: block;
}

.features {
  padding: 0;
  margin: 2rem auto;
  max-width: 300px;

  li {
    list-style: none;
    background: $orange;
    color: $white;
    font-weight: bold;
    margin: 0.5rem auto;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    position: relative;

    &::before,
    &::after {
      width: 0;
      height: 0;
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      position: absolute;
      top: 40%;
      left: 1rem;
      content: "";
      margin-top: -0.75rem;
    }

    &::before {
      border-bottom: 1.5rem solid yellow;
    }

    &::after {
      border-top: 1.5rem solid yellow;
      top: 60%;
    }
  }
}

// buttons
.button {
  background: $orange;
  color: #000;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  margin: 0 auto;
  display: block;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  border: none;

  &.large {
    font-size: 2rem;
    font-weight: normal;
    padding: 1rem;
    width: 50%;
  }

  &.muted {
    color: $darkGrey;
  }
}

.g-recaptcha {
  margin: 10px 0;
}
